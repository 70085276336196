package com.steamstreet.vegasful.browser.public

import org.w3c.dom.Element
import org.w3c.dom.HTMLElement
import org.w3c.dom.asList

class TagFilterEvent(
    val id: String, val tags: List<String>,
    val venue: String? = null, date: String? = null
) {}

fun Element.appAttribute(key: String): String? {
    return this.getAttribute("data-vegasful-${key}")
}

/**
 * Manages filtering with a set of filterable elements and events.
 */
fun eventTagFilter(element: Element) {
    var filter: String? = null
    val events = element.querySelectorAll("[data-vegasful-entity-type=event]").asList().mapNotNull {
        val eventElement = it as Element
        val id = eventElement.appAttribute("id")
        val venue = eventElement.appAttribute("venue")
        val date = eventElement.appAttribute("date")
        val tags = eventElement.appAttribute("tags")?.split(",").orEmpty()

        if (id != null) {
            TagFilterEvent(id, tags, venue, date)
        } else {
            null
        }
    }

    fun hasIncludedEvents(element: Element): Boolean {
        return (element as? Element)?.querySelectorAll("[data-vegasful-excluded=include]")
            ?.asList()?.isNotEmpty() ?: false
    }

    fun checkForIncludedEvents(baseElement: Element, selector: String) {
        baseElement.querySelectorAll(selector).asList().forEach {
            val hasEvents = hasIncludedEvents(it as Element)

            (it as? Element)?.setAttribute(
                "data-vegasful-excluded", if (hasEvents) {
                    "include"
                } else "exclude"
            )
        }
    }

    fun cleanVenuesAndDates() {
        checkForIncludedEvents(element, "[data-vegasful-entity-type=venue]")
        checkForIncludedEvents(element, "[data-vegasful-date-container]")
    }

    fun updateFilter() {
        element.querySelectorAll("[data-tag-filter] a").asList().forEach {
            val path = (it as Element).getAttribute("href")?.removePrefix("/").orEmpty()
            if (path.startsWith("tag")) {
                if (filter != null && filter == path.substringAfterLast("/")) {
                    it.setAttribute("data-selected", "1")
                } else {
                    it.removeAttribute("data-selected")
                }
            }
        }
    }

    fun onFilterClick(path: String) {
        if (path.startsWith("tags")) {
            val tagId = path.substringAfterLast('/')
            filter = if (filter == tagId) {
                null
            } else {
                tagId
            }
            events.forEach {
                val eventElement = element.querySelector("[data-vegasful-id=\"${it.id}\"]") as? HTMLElement
                eventElement?.setAttribute(
                    "data-vegasful-excluded", if (filter == null || it.tags.contains(filter)) {
                        "include"
                    } else "exclude"
                )
            }

            cleanVenuesAndDates()
            updateFilter()
        }
    }

    element.querySelectorAll("[data-tag-filter] a").asList().forEach {
        val path = (it as Element).getAttribute("href")?.removePrefix("/")
        if (path != null) {
            it.addEventListener("click", {
                onFilterClick(path)
                it.preventDefault()
            })
        }
    }
}